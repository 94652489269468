.card-min-container {
    justify-content: space-between;
    overflow-x: auto;
}

.card-min-child {
    flex-basis: 32%;
    flex-shrink: 0;
    height: 100%;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.text-ellipsis-line {
    width: max-content;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-scroll {
    overflow-y: scroll;
}

.text-container {
    width: 100px;
    padding-left: 12px;
    padding-right: 12px;
}

.box {
    position: relative;
}

.box-fade {
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 1);
}

.box-fade:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    width: 100%;
    height: 320px;
}

.address-box {
    position: absolute;
    bottom: 0;
}

.typography-center {
    text-align: center;
}

.typography-end {
    align-self: end;
}

.warning-text {
    position: absolute;
    transition: bottom 0.1s ease-in-out;
    bottom: 135px;
}

.expanded {
    bottom: 150px;
}
