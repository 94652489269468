.nav {
    display: none;
}

.sticky {
    position: fixed;
    top: 120px;
}

.relative {
    position: absolute;
    bottom: 0;
    top: auto;
}

.content {
    position: relative;
    min-height: 100vh;
    margin: auto;
}

.map {
    border-radius: 16px;
}

.container {
    padding-bottom: 10vh;
}
.block-container {
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
}

.container-skeleton {
    position: relative;
    padding-left: 12px;
    grid-template-columns: repeat(2, 1fr);
    padding-right: 12px;
    padding-top: 10px;
    gap: 4px;
}

@media (min-width: 850px) {
    .nav {
        grid-area: nav;
        display: block;
        width: 240px;
    }

    .container {
        padding-bottom: 50px;
    }

    .block-container {
        grid-template-areas: 'main';
        grid-template-rows: auto;
        grid-template-columns: auto;
        column-gap: 40px;
        max-width: 1440px;
        margin: auto;
        min-height: 60vh;
        padding-right: 120px;
        padding-left: 120px;
        background-color: var(--color-background-primary);
    }

    .main {
        grid-area: main;
    }

    .container-skeleton {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 40px;
        row-gap: 48px;
        max-width: 940px;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-top: 100px;
    }
}
