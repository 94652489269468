:root {
    --color-background-primary: rgba(255, 255, 255, 1);
    --color-background-secondary: rgba(235, 241, 237, 1);
    --color-background-secondary-hovered: rgba(205, 219, 211, 1);
    --color-background-light: rgba(244, 244, 244, 1);
    --color-background-heavy: rgba(236, 236, 236, 1);
    --color-background-brand-green: rgba(3, 77, 33, 1);
    --color-background-brand-green-hovered: rgba(36, 113, 67, 1);
    --color-background-brand-heavy: rgba(205, 219, 211, 1);
    --color-background-brand-orange: rgba(253, 107, 0, 1);
    --color-background-brand-orange-hovered: rgba(253, 122, 26, 1);

    --color-border-primary: rgba(236, 236, 236, 1);
    --color-border-primary-inverse: rgba(26, 26, 26, 1);
    --color-border-brand: rgba(3, 77, 33, 1);
    --color-border-brand-hovered: rgba(174, 198, 184, 1);
    --color-border-error: rgba(202, 50, 20, 1);
    --color-border-error-hovered: rgba(229, 77, 46, 1);
    --color-border-warning: rgba(236, 146, 21, 1);
    --color-border-warning-hovered: rgba(248, 174, 69, 1);
    --color-border-success: rgba(106, 153, 46, 1);
    --color-border-success-hovered: rgba(158, 209, 95, 1);

    --color-text-primary: rgba(26, 26, 26, 1);
    --color-text-secondary: rgba(79, 79, 79, 1);
    --color-text-primary-inverse: rgba(255, 255, 255, 1);
    --color-text-light: rgba(207, 207, 207, 1);
    --color-text-heavy: rgba(149, 149, 149, 1);
    --color-text-brand-green: rgba(3, 77, 33, 1);
    --color-text-brand-green-hovered: rgba(79, 130, 100, 1);
    --color-text-brand-orange: rgba(253, 107, 0, 1);
}
